// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat-theming;
@use 'sass:map';

@use './lucid.palette.scss' as lucid;

// Plus imports for other components in your app.
@use '@identic/core';
@use '@identic/layout';
@use '@identic/chat';
@use '@identic/controls';
@use '@identic/licence';
@use '@identic/log-to-db';
@use '@identic/notification';
@use '@identic/scheduler';

// Include the common styles for Angular Material. We include this here so that you only have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat-theming.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat-theming.legacy-typography-hierarchy(mat-theming.define-typography-config());`
@include mat-theming.all-component-typographies();
@include mat-theming.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss (imported above as 'mat').
// For each palette, you can optionally specify a default, lighter, and darker hue. Available color palettes: https://material.io/design/color/
// $app-primary: mat-theming.define-palette(mat-theming.$blue-palette);
// $app-accent: mat-theming.define-palette(mat-theming.$yellow-palette, A200, A100, A400);
// $app-warn: mat-theming.define-palette(mat-theming.$red-palette);    // The warn palette is optional (defaults to red).

// Create the theme object. A theme consists of configurations for individual theming systems such as "color" or "typography".
// $app-light-theme: mat-theming.define-light-theme($app-primary, $app-accent, $app-warn);
// $app-light-theme: mat-theming.define-dark-theme($app-primary, $app-accent, $app-warn);

$app-cutomisations: (
  layout: (
    toolbar-height: 83px,
    mobile-toolbar-height: 16px,
  ),
  controls: (
    loading-bar-height: 3px,
    loading-bar-margin-bottom: 1px,
    // toast-allowed-in-header: true,
  ),
  // chat: (
  //   send-colour: lightgreen,
  //   receive-colour: lightblue,
  //   avatar-size: 40px,         // width & height are the same
  //   customised-classes: (
  //     chat-content: (),
  //     chat-content-empty: (),
  //     chat-spinner: (),
  //     chat-new-message: (),
  //     chat-new-message-send-btn(),
  //     chat-container: (),
  //     chat-item: (),
  //     chat-item-avatar: (),
  //     chat-item-message: (),
  //     chat-item-message-info: (),
  //   )
  // )
);

@mixin app-theme($theme-config) {
  $app-theme: map.merge($theme-config, $app-cutomisations);

  // Add Amgular material controls theme
  @include mat-theming.all-component-themes($app-theme);

  // Add themes for identic components used in this app
  @include layout.lib-theme($app-theme);
  @include controls.lib-theme($app-theme);
  @include chat.lib-theme($app-theme);
  @include licence.lib-theme($app-theme);
  @include log-to-db.lib-theme($app-theme);
  @include notification.lib-theme($app-theme);
  @include scheduler.lib-theme($app-theme);
}

// Default palettes
$accent-default-palette: mat-theming.define-palette(mat-theming.$yellow-palette, A200, A100, A400);
$warn-default-palette: mat-theming.define-palette(mat-theming.$red-palette);

@mixin app-define-light-and-dark-themes($theme-name, $primary-palette, $accent-palette: $accent-default-palette, $warn-palette: $warn-default-palette) {
  .#{$theme-name} {
    &-theme {

      @include app-theme(mat-theming.define-light-theme((
        color: (
          primary: $primary-palette,
          accent: $accent-palette,
          warn: $warn-palette,
        ),
        typography: mat-theming.define-typography-config(),
      )));
    }
    &-dark-theme {

      @include app-theme(mat-theming.define-dark-theme((
        color: (
          primary: $primary-palette,
          accent: $accent-palette,
          warn: $warn-palette,
        ),
        typography: mat-theming.define-typography-config(),
      )));
    }
  }
}

// ------------------------------------------------
// Themes that can be set with /theme/<theme-name>

// i.e. ?/theme=default or ?/theme=default-dark
@include app-define-light-and-dark-themes(default, mat-theming.define-palette(mat-theming.$blue-palette));

// i.e. ?/theme=lucid or ?/theme=lucid-dark
@include app-define-light-and-dark-themes(lucid, mat-theming.define-palette(lucid.$palette));

// i.e. ?/theme=uq or ?/theme=uq-dark
@include app-define-light-and-dark-themes(uq, mat-theming.define-palette(layout.$uq-palette));
